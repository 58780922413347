$("#contact-form #email").attr("name", 'myemail');

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});
$('#contact-form').on('submit', function (event) {
    event.preventDefault();
    $('.form-error').text('');

    url = $('#contact-form').attr('action');
    data = $('#contact-form').serialize();

    $.ajax({
        url: url,
        type: "POST",
        data: data,
        success: function (response) {
            console.log(response);
            if (response) {
                $("#contact-form")[0].reset();
                $('#submit').hide();
                $('#success-message').text(response.success);
            }
        },
        error: function (response) {
            $('#brand-error').text(response.responseJSON.errors.brand);
            $('#email-error').text(response.responseJSON.errors.myemail);
            $('#subject-error').text(response.responseJSON.errors.subject);
            $('#terms-error').text(response.responseJSON.errors.terms);
        }
    });
});
